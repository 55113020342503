<template>
  <button
    :class="'dark:box-s relative border-zinc-900 bg-white text-sm dark:border-zinc-100 dark:bg-gray-800 dark:text-gray-100 flex'"
    :disabled="state === 'loading' || state === 'disabled'"
    type="submit">
    <span v-if="state === 'loading'"> {{ $t('Loading…') }} </span>

    <PlusIcon v-if="icon === 'plus' && state !== 'loading'" />

    <CheckedIcon v-if="icon === 'check' && state !== 'loading'" />

    <MinusIcon v-if="icon === 'minus' && state !== 'loading'" />

    <span v-if="state !== 'loading'">
      {{ text }}
    </span>

    <ArrowIcon :type="'right'" :size="'big'" v-if="icon === 'arrow' && state !== 'loading'" />
  </button>
</template>

<script>
import PlusIcon from '@/Shared/Icons/PlusIcon.vue';
import CheckedIcon from '@/Shared/Icons/CheckedIcon.vue';
import MinusIcon from '@/Shared/Icons/MinusIcon.vue';
import ArrowIcon from '@/Shared/Icons/ArrowIcon.vue';

export default {
  components: {
    PlusIcon,
    CheckedIcon,
    MinusIcon,
    ArrowIcon,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  top: -1px;
}

.save {
  background-color: #fcf27e;
}

.dark .save {
  background-color: #d0c10d !important;
  color: rgb(31 41 55) !important; // text-gray-800
}

button {
  --tw-shadow: 2px 2px 0 #191a1b !important;
  border-radius: 0.25rem !important;
  border-width: 1px !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow) !important;
  display: inline-block !important;
  position: relative !important;
  text-decoration: none !important;
  transition-duration: 0.15s !important;
  transition-property:
    background-color,
    border-color,
    color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter,
    -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  padding-left: 9px;
  padding-right: 9px;

  &:hover {
    box-shadow: none !important;
    transform: translate(2px, 2px);
  }

  &:disabled {
    box-shadow: none;
    transform: translate(0, 0);
  }
}

.dark button {
  --tw-shadow: 2px 2px 0 rgb(242, 242, 245) !important;
}
</style>
